import React from 'react';
import Styles from './pageloader.module.scss';

/**
 * page loader
 * @returns {*}
 */
const PageLoader = function () {
  return (

    <div className={Styles.loader_area}>
      <img src="/loader.svg" alt="Page Loader" />
    </div>

  );
};

/**
 * export
 */
export default PageLoader;
