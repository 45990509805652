import axios from 'axios';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import setAuthToken from 'setAuthToken';
import Actions from './types';

/**
 * Set current user
 * @constant
 * @param {*} decoded doceded
 * @returns {*}
 */
export const setCurrentUser = (decoded) => ({
  type: Actions.SET_CURRENT_USER,
  payload: decoded,
});

/**
 * Login User
 * @constant
 * @param {*} user user detail
 * @returns {*}
 */
export const loginUser = (user) => (dispatch) => {
  axios
    .post('/auth/login', user)
    .then((res) => {
      const { token } = res.data._data;
      localStorage.setItem('jwtToken', token);
      setAuthToken(token);
      const decoded = jwtDecode(token);
      dispatch(setCurrentUser(decoded));
      toast.success('Logged In');
    })
    .catch((err) => {
      toast.error(err.response.data._message);
      dispatch({
        type: Actions.GET_AUTH_ERRORS,
        payload: err.response.data,
      });
    });
};

/**
 * Logout user
 * @constant
 * @returns {*}
 */
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('jwtToken');
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  window.location = '/login';
  toast.success('see you soon');
};

/**
 * Reset Password
 * @constant
 * @param {*} data reset password data
 * @returns {*}
 */
export const resetPassword = (data) => (dispatch) => {
  axios
    .post('/auth/resetPassword', data)
    .then(() => {
      toast.success('Log In with new password');
      window.location = '/login';
    })
    .catch((err) => {
      dispatch({
        type: Actions.GET_AUTH_ERRORS,
        payload: err.response?.data,
      });
    });
};

/**
 * Forgot password
 * @constant
 * @param {String} email email
 * @returns {*}
 */
export const forgetPassword = (email) => (dispatch) => {
  axios
    .get(`/auth/forgetPassword?email=${email}`)
    .then(() => {
      toast.success('Please check your mail');
    })
    .catch((err) => {
      toast.error('invalid Email');
      dispatch({
        type: Actions.GET_AUTH_ERRORS,
        payload: err.response?.data,
      });
    });
};
