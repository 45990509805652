import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Axios from 'axios';
import jwtDecode from 'jwt-decode';
import setAuthToken from './setAuthToken';
import { setCurrentUser, logoutUser } from './actions/auth';
import store from './store';
import routes from './route';
import PageLoader from './components/PageLoader';
import AuthRouter from './authRouter';
import './App.scss';

const Resetpassword = lazy(() => import('./pages/Login/Resetpassword'));
const Login = lazy(() => import('./pages/Login'));

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwtDecode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
  }
}

/**
 * The function returns a higher-order component that wraps a given component in a Suspense component
 * with a fallback loader.
 *
 * @return A higher-order function that takes a component as an argument and returns a new component
 * wrapped in a `Suspense` component with a fallback of `<PageLoader />`. This new component will
 * render the original component with any props passed to it.
 */
function WaitingComponent(Component) {
  return function (props) {
    return (
      <Suspense fallback={<PageLoader />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

const App = function () {
  useEffect(() => {
    if (!localStorage.shopId && localStorage.jwtToken) {
      Axios({
        url: 'shops/options',
        method: 'GET',
        headers: {
          Authorization: localStorage.jwtToken,
        },
      }).then((response) => {
        const data = response?.data?._data || {};
        const currentValue = data[0]?.value;
        localStorage.setItem('shopId', currentValue);
      });
    }
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="App">
      <Switch>
        <Route path="/login" exact component={WaitingComponent(Login)} />
        <Route path="/resetpassword" exact component={WaitingComponent(Resetpassword)} />
        {routes.map((module) => (
          <AuthRouter path={module.path} exact={module.exact} component={WaitingComponent(module.component)} key={module.path} />
        ))}
      </Switch>
    </div>
  );
};

export default App;
