import React, { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Container from 'components/container';
import MainMenu from 'components/MainMenu';

/**
 *Authenticated Route function
 * @param {*} param0 auth route params
 * @returns {*}
 */
const AuthenticatedRoute = function ({ component: Component, ...rest }) {
  useEffect(() => {
    if (!rest.isAuthenticated) {
      rest.history.push('/login');
    }
  });

  return (
    rest.isAuthenticated && (
      <div className="authcontent">
        <MainMenu />
        <Container>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </Container>
      </div>
    )
  );
};

/**
 * Map state to propss
 */
const mapStateToProps = (state) => ({
  ...state.authReducer,
});

/**
 * Prototypes
 */
AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

/**
 * Export
 */
export default withRouter(connect(mapStateToProps)(AuthenticatedRoute));
