/* eslint-disable default-param-last */
import Actions from 'actions/types';
import { isEmpty } from 'helper';

/**
 * initial state
 * @constant
 */
const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  errors: null,
};

/**
 * Auth reducer
 * @param {*} state state
 * @param {*} action action
 * @returns {*}
 */
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case Actions.GET_AUTH_ERRORS:
      return {
        ...state,
        errors: action.payload?._errors,
      };
    default:
      return state;
  }
};

export default authReducer;
