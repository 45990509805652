import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { logoutUser } from 'actions/auth';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LogoutConfirmWindow from 'components/LogoutConfirmWindow';
import MenuItem from './MenuItem';
import Styles from './mainmenu.module.scss';

/**
 * Mail menu
 * @param {*} param0 props
 * @returns {*}
 */
const MainMenu = function ({ history, children, ...props }) {
  const main = [
    {
      title: 'Dashboard',
      link: '/',
    },
  ];

  const sub = [
    {
      title: 'Category',
      link: '/category',
      buttonlabel: 'Create category',
      buttonlink: '/category/create',
    },
    {
      title: 'Icons',
      link: '/badgesandlabels',
      buttonlabel: 'Create Badges AND Labels',
      buttonlink: '/badgesandlabels/create',
    },
    {
      title: 'Shops',
      link: '/shops',
    },
    {
      title: 'Subscriptions',
      link: '/subscriptions',
    },
    {
      title: 'Plans',
      link: '/plans',
      buttonlabel: 'Create Plan',
      buttonlink: '/plans/create',
    },
    {
      title: 'Requests',
      link: '/requests/badge',
      link2: '/requests/theme',
    },
    {
      title: 'Settings',
      link: '/settings',
      // buttonlabel: 'Add recepient',
      // buttonlink: '/settings/add',
    },
    {
      title: 'Search',
      link: '/search',
    },
  ];

  /**
   * Handle logout
   */
  function handlelogout() {
    props.logoutUser();
  }
  return (
    <div className={Styles.header_block}>
      <div className={Styles.main_menu_block}>
        <div className={Styles.menu_left}>
          <Link to="/" className={Styles.logo}>
            <img src="/logo192.png" alt="Logo" />
            <span>Product Badges</span>
          </Link>
          <span className={Styles.role}>Admin</span>
        </div>

        <div className={Styles.menu_right}>
          <ul>
            {main?.map(((item) => (
              <MenuItem
                key={item.title}
                title={item.title}
                link={item.link}
                active={(history.location.pathname === item.link) ? Styles.active : ''} />
            )))}
          </ul>
          <div className={Styles.logout}>
            <LogoutConfirmWindow onLogout={() => handlelogout()} />
          </div>
        </div>
      </div>
      <div className={Styles.sub_menu_block}>
        <ul>
          {sub?.map((item) => {
            const Icon = item.icon;
            return (
              <MenuItem
                key={item.title}
                title={item.title}
                link={item.link}
                icon={item.icon}
                active={(history.location.pathname === item.link) || (history.location.pathname === item.link2) ? Styles.active : ''}
                buttonlabel={item.buttonlabel}
                buttonlink={item.buttonlink}
                buttonlabel2={item.buttonlabel2}
                buttonlink2={item.buttonlink2}
                buttonstyle={item.buttonlabel && Styles.add_button}
                className={Styles.right_btns}
              >
                {Icon && <Icon active={history.location.pathname === item.link} />}
              </MenuItem>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

/**
 * mapStateToProps
 * @param {*} state state
 * @returns {*}
 */
const mapStateToProps = (state) => ({
  ...state.authReducer,
});

/**
 * proptypes
 */
MainMenu.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  children: PropTypes.any,
};

/**
 * default proptypes
 */
MainMenu.defaultProps = {
  children: [],
};

/**
 * export
 */
export default withRouter(connect(mapStateToProps, { logoutUser })(MainMenu));
