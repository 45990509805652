/* eslint-disable prefer-regex-literals */
import moment from 'moment';

/**
 * For filter object using keys
 * @constant
 * @param {Object} object Object need to be filterd
 * @param {String} filterKey Key used to filter the object
 * @param {Boolena} equals boolean operator
 * @returns {Object}
 */
export const filterObjects = (object, filterKey, equals = true) => {
  return Object.fromEntries(Object.entries(object).filter(([, v]) => (equals ? v === filterKey : v !== filterKey)));
};

/**
 * Check if date
 * @constant
 * @param {String} dateString date
 * @returns {*}
 */
export const checkIsDate = (dateString) => {
  return !new RegExp(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/).test(dateString);
};

/**
 * For filter Multi level objects using keys
 * @constant
 * @param {Object} object Object need to be filterd
 * @param {String} filterKey Key used to filter the object
 * @param {Boolena} equals boolean operator
 * @returns {Object}
 */
export const filterMutlilevelObjects = (object, filterKey, equals = true) => {
  return Object.fromEntries(
    Object.entries(object)
      .map(([k, v]) => {
        if (typeof v === 'object') {
          return [k, filterMutlilevelObjects(v, filterKey, equals)];
        }
        return [k, equals ? v === filterKey : v !== filterKey && v];
      })
      .filter(([, v]) => v !== false),
  );
};

/**
 * Checks if the field is empty
 * @constant
 * @param {*} value value to check
 * @returns {*}
 */
export const isEmpty = (value) => value === undefined
  || value === null
  || (typeof value === 'object' && Object.keys(value).length === 0)
  || (typeof value === 'string' && value.trim().length === 0);

/**
 * Return date range for analytics report
 * @constant
 * @param {*} customDays custom days
 * @returns {*}
 */
export const dateRangeForAnalyticReport = (customDays) => {
  const startDate = moment().subtract(customDays, 'days');
  const endDate = moment();
  const datePickerRange = 'custom';
  const name = 'createdAt';
  return { name, startDate, endDate, datePickerRange };
};

/**
 * Date Formate function
 * @constant
 * @param {*} date1 date
 * @returns {*}
 */
export const formatDate = (date1) => {
  if (isEmpty(date1)) return '[No Data]';

  let date = date1;
  if (typeof date === 'undefined' || typeof date === 'undefined') {
    return '';
  }
  date = new Date(date);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hrs = date.getHours();
  const minutes = ((date.getMinutes() < 10 ? '0' : '') + date.getMinutes());

  return `${day} ${monthNames[monthIndex]} , ${+year}  at  ${hrs !== 12 ? hrs % 12 : hrs}:${minutes}${hrs / 12 >= 1 ? 'PM' : 'AM'}`;
};

/**
 * Find difference between a date and today date
 * @param {*} date end date
 * @returns {*}
 */
export const dateDifference = (date) => {
  if (!date) return 'NILL';
  const date1 = moment(date);
  const date2 = moment();
  const diff = date1.diff(date2, 'days');
  if (diff >= 0) return diff;
  return 'Plan Expired';
};

/**
 * Get nested prop value of object
 * @constant
 * @param {Object} obj object
 * @param {*} prop props
 * @return {*}
 */
export const getObjectProp = (obj, prop) => {
  if (typeof obj === 'string') {
    return obj;
  }
  if (typeof prop === 'undefined') {
    return false;
  }
  const _index = prop.indexOf('.');
  if (_index > -1) {
    return getObjectProp(
      obj?.[prop?.substring(0, _index)],
      prop?.substr(_index + 1),
    );
  }
  return obj?.[prop];
};

/**
 * Return header fields
 * @constant
 * @param {Array} passedItems Array of selected fields
 * @returns {*} headers fields
 */
export const getHeaderFields = (passedItems) => {
  if (passedItems && passedItems.length) {
    const headerFields = {};
    passedItems.forEach((item) => {
      if (!headerFields[item.parentTable]) headerFields[item.parentTable] = [];
      headerFields[item.parentTable].push(item.name);
    });
    return headerFields;
  }
  return {};
};

/**
 * Join css class names
 * @param {*} classes claases
 * @returns {*}
 */
export function ClassNames(classes) {
  return classes.join(' ');
}

/**
 * String Camelizer
 * @param {String} str string
 * @returns {String} camelized string
 */
export function stringCamelizer(str) {
  if (str && typeof str === 'string') {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }
  return str;
}

/**
 * Decamelizer funtion
 * @param {String} str String to decamelize
 * @param {String} separator seperator
 * @return {String} Decamelized string
 */
export function deCamelizer(str, separator = ' ', capitalize = false) {
  if (!str) return null;
  const returnString = str
    .replace(/([a-z\d])([A-Z])/g, `$1${separator}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${separator}$2`);
  return capitalize ? returnString.toUpperCase() : returnString.toLowerCase();
}

/**
 * Compare objects
 * @param {Object} obj1 object 1
 * @param {Object} obj2 object 2
 * @returns {*}
 */
export function compareObjects(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

/**
 * JSON parse
 * @constant
 * @param {*} data data to parse
 * @return {*} json parsed data
 */
export const parseJson = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};

/**
 * Extract schedule data depend on the advanced schedule type
 * @param {String} scheduleType Schedule type
 * @param {String} advanceScheduleType Advanced schedule type
 * @param {Object} scheduleRange Schedule range
 * @param {Object} scheduleDelivery Schedule delivery
 * @param {Object} outputOption Mail Delivery options
 * @returns Extracted schedule data
 */
export function extractScheduleData(scheduleType, advanceScheduleType, scheduleRange, scheduleDelivery, outputOption) {
  if (isEmpty(scheduleRange) === false) {
    let range = {};
    if (advanceScheduleType === 'NND') {
      const { dayCount } = scheduleRange;
      range = { dayCount };
    }
    if (advanceScheduleType === 'SSD') {
      const { scheduleDate } = scheduleRange;
      range = { scheduleDate };
    }
    if (advanceScheduleType === 'CDR') {
      const { fromDate, toDate, fromTime, toTime } = scheduleRange;
      range = { fromDate, toDate, fromTime, toTime };
    }
    if (advanceScheduleType === 'CTR') {
      const { from, to } = scheduleRange;
      range = { from, to };
    }
    return range;
  }
  if (isEmpty(scheduleDelivery) === false) {
    let delivery = {};
    if (scheduleType === 'H') {
      const { scheduleHour } = scheduleDelivery;
      delivery = { scheduleHour };
    }
    if (scheduleType === 'D') {
      const { dailyTime } = scheduleDelivery;
      delivery = { dailyTime };
    }
    if (scheduleType === 'W') {
      const { weektime, weekday } = scheduleDelivery;
      delivery = { weektime, weekday };
    }
    if (scheduleType === 'M') {
      const { dayCount, time, weekdatas, week } = scheduleDelivery;
      delivery = { dayCount, time, weekdatas, week };
    }
    return delivery;
  }
  if (isEmpty(outputOption) === false) {
    let output = {};
    if (outputOption.value === 'email') {
      const { value, filetype, attachment, email, ccemail, bccemail, emailcontent } = outputOption;
      output = {
        value,
        filetype,
        attachment,
        email,
        ccemail,
        bccemail,
        emailcontent,
      };
    }
    if (outputOption.value === 'Googledrive') {
      const { value, filetype, attachment, email } = outputOption;
      output = { value, filetype, attachment, email };
    }
    return output;
  }
  return null;
}

/**
 * Get Params From Location Search
 * @constant
 * @param {*} search search
 * @param {Array} fields fields
 * @returns {*}
 */
export const getParamsFromSearch = (search, fields = []) => {
  const paramsObject = {};
  const params = new URLSearchParams(search);
  fields.forEach((field) => {
    paramsObject[field] = params.get(field)?.replace(/"/g, '');
  });
  return paramsObject;
};

/**
 * Get Analytics period label
 * @constant
 * @param {String} val Analytics period
 * @returns {*}
 */
export const getPeriodLabel = (val) => {
  let periodLabel = '';
  switch (val) {
    case 365:
      periodLabel = 'Last Year';
      break;
    case 30:
      periodLabel = 'Last Month';
      break;
    case 7:
      periodLabel = 'Last Week';
      break;
    default:
      break;
  }
  return periodLabel;
};

/**
 * Get analytics periods
 * @constant
 * @param {String} val Analytics period
 * @returns {Array} periods array
 */
export const getPeriods = (val) => {
  const periods = [];
  switch (val) {
    case 365:
    case 'all':
      for (let i = 1; i <= 12; i += 1) {
        const date = moment().subtract(i, 'months').toDate();
        periods.push(moment(date).format('MMM'));
      }
      break;
    case 30:
      for (let i = 1; i <= 4; i += 1) {
        const date = moment().subtract(i, 'weeks').week();
        periods.push(date);
      }
      break;
    case 7:
      for (let i = 1; i <= 7; i += 1) {
        const date = moment().subtract(i, 'days').toDate();
        periods.push(moment(date).format('ddd'));
      }
      break;
    case 'today':
      for (let i = 1; i <= 24; i += 1) {
        periods.push(i < 10 ? `0${i}` : i);
      }
      break;
    default:
      break;
  }
  return periods;
};

/**
 * Add ordinal suffix to analytics periods in case of weeks
 * @constant
 * @param {Number} i index
 * @returns {String} Period with ordinal suffix
 */
export const ordinalSuffixOf = (i) => {
  if (i === 1) {
    return `${i}st`;
  }
  if (i === 2) {
    return `${i}nd`;
  }
  if (i === 3) {
    return `${i}rd`;
  }
  return `${i}th`;
};

/**
 * Return period wise data for analytics
 * @constant
 * @param {String} name analytics report name
 * @param {Array} periods periods array
 * @param {String} currentPeriod current period
 * @param {*} data datas
 * @returns {*}
 */
export const getPeriodData = (name, periods, currentPeriod, data) => {
  let originalPeriod = [];
  let originalData = [];
  originalPeriod = originalPeriod.reverse();
  originalData = originalData.reverse();
  switch (name) {
    case 'taxData':
    case 'refundData':
      if (data) {
        originalPeriod = originalPeriod.slice(-5);
        originalData = originalData.slice(-5);
      }
      break;
    default:
      periods.map((period, index) => {
        const sum = data[period] || 0;
        if (currentPeriod === 30) {
          originalPeriod.push(`${ordinalSuffixOf(index + 1)} Week`);
        } else {
          originalPeriod.push(period);
        }
        originalData.push(sum);
        return true;
      });
      break;
  }
  return { originalPeriod, originalData };
};

/**
 * Get Percentage Change
 * @constant
 * @param {*} data1 current data
 * @param {*} data2 previous data
 * @returns {Number} percentage change
 */
export const getPercentageChange = (data1, data2) => {
  let currentData = 0;
  let previousData = 0;
  if (data1) {
    currentData = data1;
  }
  if (data2) {
    previousData = data2;
  }
  const diff = currentData - previousData;
  let percentage = (diff * 100) / previousData;
  if (percentage < 0) {
    percentage *= -1;
  }
  percentage = percentage.toFixed(1);
  return percentage;
};

export const appendToFormData = (datas) => {
  const formData = new FormData();
  Object.keys(datas).forEach((data) => {
    if (datas[data] instanceof Array && datas[data][0] instanceof File) {
      for (let i = 0; i < datas[data].length; i += 1) {
        formData.append(data, datas[data][i]);
      }
    } else if (datas[data] instanceof File || typeof datas[data] !== 'object') {
      formData.append(data, datas[data]);
    } else if (datas[data] instanceof Date && datas[data].getTime()) {
      formData.append(data, datas[data]);
    } else {
      formData.append(data, JSON.stringify(datas[data]));
    }
  });
  return formData;
};

/**
   * Gets the width and height of an image.
   * @param {string} image - the image to get the dimensions of
   * @returns {Promise<{width: number, height: number}>} - the width and height of the image
   */
export const getHeightAndWidthOfImage = (image) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
  });
};

/**
 * The function checks if a given string is a valid JSON string.
 * @param str str is a string parameter that represents a JSON string that needs to be validated. The
 * function checks whether the input string is a valid JSON string or not.
 * @return The function `isJsonString` returns a boolean value. It returns `true` if the input string
 * can be parsed as a valid JSON object, and `false` otherwise.
 */
export const isJsonString = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
};
