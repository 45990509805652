import { lazy } from 'react';

const Dashboard = lazy(() => import('./pages/dashboard/index'));
const Login = lazy(() => import('./pages/Login'));
const Category = lazy(() => import('./pages/category/List'));
const Createcategory = lazy(() => import('./pages/category/Create'));
const Editcategory = lazy(() => import('./pages/category/Edit'));
const Resetpassword = lazy(() => import('./pages/Login/Resetpassword'));
const Item = lazy(() => import('./pages/items/List'));
const CreateItem = lazy(() => import('./pages/items/Create'));
const EditItem = lazy(() => import('./pages/items/Edit'));
const Shops = lazy(() => import('./pages/shops/List'));
const ShopsDetails = lazy(() => import('./pages/shops/shopdetails'));
const StoreJson = lazy(() => import('./pages/shops/storeJson'));
const badges = lazy(() => import('./pages/shops/badgeList'));
const shopAddClass = lazy(() => import('./pages/shops/addClass'));
const Subscription = lazy(() => import('./pages/subscriptions/List'));
const editSubscription = lazy(() => import('./pages/subscriptions/Edit'));
const Plans = lazy(() => import('./pages/plans/List'));
const createPlans = lazy(() => import('./pages/plans/Create'));
const editPlan = lazy(() => import('./pages/plans/Edit'));
const badgeReq = lazy(() => import('./pages/requests/BadgeList'));
const themeReq = lazy(() => import('./pages/requests/ThemeList'));
const editRequest = lazy(() => import('./pages/requests/Edit'));
const Settings = lazy(() => import('./pages/settings/List'));
const editSettings = lazy(() => import('./pages/settings/Edit'));
const AddSettings = lazy(() => import('./pages/settings/Add'));
const Search = lazy(() => import('./pages/search/List'));
const editSearch = lazy(() => import('./pages/search/Edit'));

const route = [
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/',
    exact: true,
    component: Dashboard,
  },
  {
    path: '/resetpassword',
    exact: true,
    component: Resetpassword,
  },
  {
    path: '/category',
    exact: true,
    component: Category,
  },
  {
    path: '/category/create',
    exact: true,
    component: Createcategory,
  },
  {
    path: '/category/edit/:id',
    exact: true,
    component: Editcategory,
  },
  {
    path: '/badgesandlabels',
    exact: true,
    component: Item,
  },
  {
    path: '/badgesandlabels/create',
    exact: true,
    component: CreateItem,
  },
  {
    path: '/badgesandlabels/edit/:id',
    exact: true,
    component: EditItem,
  },
  {
    path: '/shops',
    exact: true,
    component: Shops,
  },
  {
    path: '/subscriptions',
    exact: true,
    component: Subscription,
  },
  {
    path: '/subscriptions/edit/:id',
    exact: true,
    component: editSubscription,
  },
  {
    path: '/shops/shopdetails/:id',
    exact: true,
    component: ShopsDetails,
  },
  {
    path: '/shops/storeJson/:shopId/:id/:shop',
    exact: true,
    component: StoreJson,
  },
  {
    path: '/shops/addClass/:shop',
    exact: true,
    component: shopAddClass,
  },
  {
    path: '/shops/badgelist/:id/:shop',
    exact: true,
    component: badges,
  },
  {
    path: '/plans',
    exact: true,
    component: Plans,
  },
  {
    path: '/plans/create',
    exact: true,
    component: createPlans,
  },
  {
    path: '/plans/edit/:id',
    exact: true,
    component: editPlan,
  },
  {
    path: '/requests/badge',
    exact: true,
    component: badgeReq,
  },
  {
    path: '/requests/theme',
    exact: true,
    component: themeReq,
  },
  {
    path: '/requests/view/:id',
    exact: true,
    component: editRequest,
  },
  {
    path: '/settings',
    exact: true,
    component: Settings,
  },
  {
    path: '/settings/edit/:id',
    exact: true,
    component: editSettings,
  },
  {
    path: '/settings/add',
    exact: true,
    component: AddSettings,
  },
  {
    path: '/search',
    exact: true,
    component: Search,
  },
  {
    path: '/search/edit/:id',
    exact: true,
    component: editSearch,
  },
];

export default route;
