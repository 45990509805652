import React from 'react';
import PropTypes from 'prop-types';
import Styles from './container.module.scss';

/**
 * Container
 * @param {*} param0 children
 * @returns {*}
 */
const Container = function ({ children }) {
  return (

    <div className={Styles.maincontainer}>
      {children}
    </div>

  );
};

/**
 * Proptypes
 */
Container.propTypes = {
  children: PropTypes.any,
};

/**
 * default proptypes
 */
Container.defaultProps = {
  children: [],
};

/**
 * export
 */
export default Container;
