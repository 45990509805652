import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Provider as ReduxProvider } from 'react-redux';
import axios from 'axios';
import { AppProvider } from '@shopify/polaris';
// import { Provider } from '@shopify/app-bridge-react';
import store from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

console.log('app');
console.log(process.env.REACT_APP_BASE_URL);
console.log('app');
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

toast.configure({
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 2000,
});

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <BrowserRouter>
    <ReduxProvider store={store}>
      <React.StrictMode>
        <AppProvider>
          {/* <Providers> */}
          <App />
          {/* </Provider> */}
        </AppProvider>
      </React.StrictMode>
    </ReduxProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
