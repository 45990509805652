import { combineReducers } from 'redux';
import Actions from '../actions/types';
import authReducer from './authReducer';
import commonReducer from './commonReducer';

/**
 * Reducers
 */
export default combineReducers({
  authReducer,
  createCategory: commonReducer(Actions.CREATE_CATEGORY),
  listCategory: commonReducer(Actions.LIST_CATEGORY),
  getCategory: commonReducer(Actions.GET_CATEGORY),
  updateCategory: commonReducer(Actions.UPDATE_CATEGORY),
  deleteCategory: commonReducer(Actions.DELETE_CATEGORY),
  createItems: commonReducer(Actions.CREATE_ITEMS),
  getItem: commonReducer(Actions.GET_ITEM),
  listItems: commonReducer(Actions.GET_ITEM_LIST),
  deleteItems: commonReducer(Actions.DELETE_ITEMS),
  updateItem: commonReducer(Actions.UPDATE_ITEM),
  listShops: commonReducer(Actions.GET_SHOPS),
  getShopsDetails: commonReducer(Actions.GET_SHOPS_DETAILS),
  getShopsJson: commonReducer(Actions.GET_SHOPS_JSON),
  updateShopJson: commonReducer(Actions.UPDATE_SHOP_JSON),
  getBadges: commonReducer(Actions.GET_BADGES),
  getBadgeDetails: commonReducer(Actions.GET_BADGES__DETAILS),
  updateBadgeReducer: commonReducer(Actions.UPDATE_BADGES),
  listSubscription: commonReducer(Actions.GET_SUBSCRIPTION),
  getSubscriptionDetails: commonReducer(Actions.GET_SUBSCRIPTION_DETAIL),
  getProductCount: commonReducer(Actions.GET_PRODUCT_COUNT),
  getVariantCount: commonReducer(Actions.GET_VARIANT_COUNT),
  getBadgesAndLabels: commonReducer(Actions.GET_BADGES_AND_LABELS),
  getAllPlans: commonReducer(Actions.GET_ALL_PLANS),
  createPlans: commonReducer(Actions.CREATE_PLANS),
  getPlansDetails: commonReducer(Actions.GET_PLANS_DETAIL),
  updatePlanReducer: commonReducer(Actions.UPDATE_PLANS),
  deleteProducts: commonReducer(Actions.DELETE_PRODUCTS),
  deleteVariants: commonReducer(Actions.DELETE_VARIANTS),
  listRequests: commonReducer(Actions.GET_REQUESTS),
  getRequestDetails: commonReducer(Actions.GET_REQUEST_DETAIL),
  updateRequest: commonReducer(Actions.UPDATE_REQUEST),
  getSettings: commonReducer(Actions.GET_SETTINGS),
  addSettings: commonReducer(Actions.ADD_SETTINGS),
  deleteSettings: commonReducer(Actions.DELETE_SETTINGS),
  editSettings: commonReducer(Actions.EDIT_SETTINGS),
  getSettingsDetails: commonReducer(Actions.GET_SETTINGS_DETAILS),
  getSearch: commonReducer(Actions.GET_SEARCH),
  getSearchDetails: commonReducer(Actions.GET_SEARCH_DETAILS),
  editSearch: commonReducer(Actions.EDIT_SEARCH),
  deleteSearch: commonReducer(Actions.DELETE_SEARCH),
  createSearch: commonReducer(Actions.CREATE_SEARCH),
  getInvalidate: commonReducer(Actions.GET_INVALIDATE),
  getRegenerate: commonReducer(Actions.GET_REGENERATE),
  getShopCount: commonReducer(Actions.GET_SHOP_COUNT),
  getSubscriptionCount: commonReducer(Actions.GET_SUBSCRIPTION_COUNT),
  getShopListInstalled: commonReducer(Actions.GET_SHOP_LIST_INSTALLED),
  getShopListUnInstalled: commonReducer(Actions.GET_SHOP_LIST_UNINSTALLED),
  getSubscriptionListActive: commonReducer(Actions.GET_SUBSCRIPTION_LIST_ACTIVE),
  getSubscriptionListCancelled: commonReducer(Actions.GET_SUBSCRIPTION_LIST_CANCELLED),
  getShopsInstalled: commonReducer(Actions.GET_SHOPS_INSTALLED),
  getShopsUnInstalled: commonReducer(Actions.GET_SHOPS_UNINSTALLED),
});
