import { toast } from 'react-toastify';

/**
 * initial state
 * @constant
 */
const initialState = {
  data: null,
  loading: false,
  error: null,
  metaData: {},
};

/**
 * Common reducer
 * @param {*} TYPE type
 * @returns {*}
 */
export default function testCommon(TYPE) {
  // eslint-disable-next-line default-param-last
  return (state = initialState, action) => {
    const SUCCESS = `${TYPE}_SUCCESS`;
    const FAIL = `${TYPE}_FAIL`;

    switch (action.type) {
      case TYPE:
        return { ...state, loading: true };
      case SUCCESS:
        if (action.payload && action.payload.config.method !== 'get') {
          toast.success(action.payload.data._message);
        }
        return {
          ...state,
          loading: false,
          data: action.payload.data._data,
          metaData: action.payload.data._metaData,
        };
      case FAIL:
        if (action.error.response?.data?._message) {
          toast.error(action.error.response.data._message);
        }
        return {
          ...state,
          loading: false,
          error: action.error.response?.data?._errors,
        };
      case 'CLEAR_REDUCER':
        return initialState;
      default:
        return state;
    }
  };
}
