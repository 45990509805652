import React, { useState } from 'react';
import Styles from './LogoutConfirmWindow.module.scss'; // Import the CSS file for styling

// eslint-disable-next-line no-unused-vars
function LogoutConfirmWindow({ onLogout, handleClick }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    onLogout(); // Call the logout function passed as a prop
    setIsOpen(false); // Close the confirmation window
  };

//   const onLogout = () => setIsOpen(!isOpen)

  return (
    <div className={Styles.logout_confirm_container}>
      <button type="button" className={Styles.logout_button} onClick={() => setIsOpen(true)}>
        Logout
      </button>

      {isOpen && (
        <>
          <div className={Styles.overlay} />
          <div className={Styles.confirmation_window}>
            <p className={Styles.confirmation_message}>Are you sure you want to logout?</p>
            <button type="button" className={Styles.confirm_button} onClick={handleLogout}>
              Confirm
            </button>
            <button type="button" className={Styles.cancel_button} onClick={() => setIsOpen(false)}>
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default LogoutConfirmWindow;
