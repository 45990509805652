/**
 * Actions
 */
const Actions = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  GET_AUTH_ERRORS: 'GET_AUTH_ERRORS',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  LIST_CATEGORY: 'LIST_CATEGORY',
  GET_CATEGORY: 'GET_CATEGORY',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  CREATE_ITEMS: 'CREATE_ITEMS',
  GET_ITEM: 'GET_ITEM',
  GET_ITEM_LIST: 'GET_ITEM_LIST',
  DELETE_ITEMS: 'DELETE_ITEMS',
  UPDATE_ITEM: 'UPDATE_ITEM',
  GET_SHOPS: 'GET_SHOPS',
  GET_SHOPS_DETAILS: 'GET_SHOPS_DETAILS',
  UPDATE_SHOP_JSON: 'UPDATE_SHOP_JSON',
  GET_SUBSCRIPTION: 'GET_SUBSCRIPTION',
  GET_SUBSCRIPTION_DETAIL: 'GET_SUBSCRIPTION_DETAIL',
  GET_SHOP_DETAIL: 'GET_SHOP_DETAIL',
  GET_PRODUCT_COUNT: 'GET_PRODUCT_COUNT',
  GET_VARIANT_COUNT: 'GET_VARIANT_COUNT',
  GET_BADGES_AND_LABELS: 'GET_BADGES_AND_LABELS',
  GET_ALL_PLANS: 'GET_ALL_PLANS',
  CREATE_PLANS: 'CREATE_PLANS',
  GET_PLANS_DETAIL: 'GET_PLANS_DETAIL',
  UPDATE_PLANS: 'UPDATE_PLANS',
  DELETE_PRODUCTS: 'DELETE_PRODUCTS',
  DELETE_VARIANTS: 'DELETE_VARIANTS',
  GET_BADGES: 'GET_BADGES',
  GET_BADGES__DETAILS: 'GET_BADGES__DETAILS',
  UPDATE_BADGES: 'UPDATE_BADGES',
  GET_REQUESTS: 'GET_REQUESTS',
  GET_REQUEST_DETAIL: 'GET_REQUEST_DETAIL',
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  GET_SETTINGS: 'GET_SETTINGS',
  ADD_SETTINGS: 'ADD_SETTINGS',
  DELETE_SETTINGS: 'DELETE_SETTINGS',
  GET_SETTINGS_DETAILS: 'GET_SETTINGS_DETAILS',
  EDIT_SETTINGS: 'EDIT_SETTINGS',
  GET_SEARCH: 'GET_SEARCH',
  GET_SEARCH_DETAILS: 'GET_SEARCH_DETAILS',
  EDIT_SEARCH: 'EDIT_SEARCH',
  DELETE_SEARCH: 'DELETE_SEARCH',
  CREATE_SEARCH: 'CREATE_SEARCH',
  GET_INVALIDATE: 'GET_INVALIDATE',
  GET_REGENERATE: 'GET_REGENERATE',
  GET_SHOP_COUNT: 'GET_SHOP_COUNT',
  GET_SUBSCRIPTION_COUNT: 'GET_SUBSCRIPTION_COUNT',
  GET_SHOP_LIST_INSTALLED: 'GET_SHOP_LIST_INSTALLED',
  GET_SHOP_LIST_UNINSTALLED: 'GET_SHOP_LIST_UNINSTALLED',
  GET_SUBSCRIPTION_LIST_ACTIVE: 'GET_SUBSCRIPTION_LIST_ACTIVE',
  GET_SUBSCRIPTION_LIST_CANCELLED: 'GET_SUBSCRIPTION_LIST_CANCELLED',
  GET_SHOPS_INSTALLED: 'GET_SHOPS_INSTALLED',
  GET_SHOPS_UNINSTALLED: 'GET_SHOPS_UNINSTALLED',
};

export default Actions;
