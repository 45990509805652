import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Menu items
 * @param {*} param0 menu item options
 * @returns {*}
 */
const MenuItem = function ({ link, title, active, children, className, buttonlabel, buttonlink, buttonstyle, buttonlabel2, buttonlink2 }) {
  return (
    <li>
      <Link to={link} className={active}>
        {children}
        {title}
      </Link>
      {active && (
        <div className={className}>
          <Link to={buttonlink} className={buttonstyle}>
            {buttonlabel}
          </Link>
          {buttonlabel2 && (
          <Link to={buttonlink2} className={buttonstyle}>
            {buttonlabel2}
          </Link>
          )}
        </div>
      )}
    </li>
  );
};

/**
 * proptypes
 */
MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  active: PropTypes.string,
  buttonlabel: PropTypes.string,
  buttonlink: PropTypes.string,
  buttonlabel2: PropTypes.string,
  buttonlink2: PropTypes.string,
  buttonstyle: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
};

/**
 * default proptypes
 */
MenuItem.defaultProps = {
  active: '',
  children: null,
  buttonlabel: '',
  buttonlink: '',
  buttonlabel2: '',
  buttonlink2: '',
  buttonstyle: '',
  className: '',
};

export default MenuItem;
