import { createStore, applyMiddleware, compose } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import axios from 'axios';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: 'json',
});

const middlewareConfig = {
  interceptors: {
    request: [
      {
        async success(func, req) {
          req.headers.Authorization = localStorage.getItem('jwtToken');
          req.headers.shopId = localStorage.getItem('shopId');
          return req;
        },
        error(error) {
          return error;
        },
      },
    ],
    response: [
      {
        success(func, res) {
          return Promise.resolve(res);
        },
        error(func, error) {
          console.log('API ERROR', error);
          const errorStatus = error.response.status;
          if (errorStatus === 401) {
            window.location.href = '/login';
          }
          return Promise.reject(error);
        },
      },
    ],
  },
};

const inititalState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  inititalState,
  composeEnhancers(
    applyMiddleware(thunk, axiosMiddleware(client, middlewareConfig)),
  ),
);

export default store;
